import type { PublicEnvProps } from "~/services/environment.server";

declare global {
  interface Window {
    ENV: PublicEnvProps;
  }
}

export function PublicEnv(props: PublicEnvProps) {
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `window.ENV = ${JSON.stringify(props)}`,
      }}
    />
  );
}

export function getEnv<T extends keyof PublicEnvProps>(key: T): PublicEnvProps[T] {
  //return typeof window === "undefined" ? (process.env[key] as PublicEnvProps[T]) : window.ENV[key];
  return typeof window === "undefined" ? "" : window.ENV[key];
}
